@keyframes fade-out-header{
    from{scale: 1; opacity: 1;}
    to{scale:0.8; opacity: 0;}

}

@-webkit-keyframes fade-out-header{
    from{scale: 1; opacity: 1;}
    to{scale:0.8; opacity: 0;}

}

.name__header{
    display: flex;
    animation: fade-out-header linear backwards;
    animation-timeline: view();
    animation-range: exit
}

.name__header-content{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 5rem;
}

.name__header-content h1{
    color: #121212;
    font-family: var(--font-family);
    font-weight: 800;
    font-style: normal;
    line-height: 75px;
    font-size: 62px;
    letter-spacing: -0.04em;
}

.name__header-content p{
    color: #121212;
    font-family: var(--font-family);
    font-weight: normal;
    font-style: normal;
    line-height: 27px;
    font-size: 20px;
    letter-spacing: -0.04em;
    margin-top: 1.0rem;
}

.name__header-image{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

}

.name__header-image img{
    width: 100%;
    height: 100%;

}

@media screen and (max-width: 1050px) {
    .name__header{
        flex-direction: column;
    }
    .name__header-content{
        margin: 0 0 3rem;
    }
}
@media screen and (max-width: 650px) {
    .name__header h1{
        font-size: 48px;
        line-height: 60px;
    }
    .name__header p{
        font-size: 16px;
        line-height: 24px;
    }
    .name__header-content{
        margin: 0 0 3rem;
    }
}
@media screen and (max-width: 490px) {
    .name__header h1{
        font-size: 36px;
        line-height: 48px;
    }
    .name__header p{
        font-size: 14px;
        line-height: 24px;
    }
}

