/* @keyframes fade-in-projects{
  from{scale: 0.8;  opacity: 0;}
  to{scale: 1; opacity:1;}
}

@-webkit-keyframes fade-in-projects{
  from{scale: 0.8;  opacity: 0;}
  to{scale: 1; opacity:1;}
}

.experience.experience {
    display: flex;
    margin: 0 auto;
    padding: 30px;
    color: #f3f4f6(0, 0%, 20%);
    font-family: var(--font-family);
    display: flex;
    margin: 2em;
    background-color: #eeeeee ;
    /* #EEF2F8; */
    /* border-radius: 20px;
    overflow: hidden;
    opacity: 90%;
    transition: background-color 0.3s, box-shadow 0.3s, opacity 0.3s;

    animation: fade-in-projects linear forwards;
    animation-timeline: view(); */
    /* animation-range: entry; */
    
  /* } */ 
/*  */
  @keyframes fade-in-projects {
    from {
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
      opacity: 0;
    }
    to {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
  }
  
  @-webkit-keyframes fade-in-projects {
    from {
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
      opacity: 0;
    }
    to {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
  }
  
  .experience {
    display: flex;
    margin: 0 auto;
    padding: 30px;
    color: #f3f4f6(0, 0%, 20%);
    font-family: var(--font-family);
    display: flex;
    margin: 2em;
    background-color: #f2f2f2 ;
    /* #EEF2F8; */
    border-radius: 20px;
    overflow: hidden;
    opacity: 90%;
    transition: background-color 0.3s, box-shadow 0.3s, opacity 0.3s;

  
    animation: fade-in-projects linear forwards;
    animation-timeline: view();
    animation-range: entry;
  }
  
  
  .experience h1 {
    font-size: 2em;
    margin-bottom: 0.5em;
  }
  

  .experience:hover{
        background-color:#e9eaeb;
        box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2);
        opacity: 100%;
  }
  
  .experience-description {
    padding: 20px;
    width: 80%;
  }

  
  .experience-description h2 {
    font-size: 2em;
    margin-bottom: 0.1em;
  }
  
  .experience-description .company {
    color: #666;
  }
  
  .experience-description p {
    line-height: 1.6;
    font-size: 1.2em;
    margin-bottom: 0.5em;
  }

  .experience-image {
    width: 160px;
    padding-top: 20px;
    margin-right: 15px;
  }
  
  .experience-image img {
    width: 100px;
    height: 100px;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s, opacity 0.3s;
    margin-left: 30px;
  }
  
  .experience-image img:hover {
    opacity: 100%;
    box-shadow: 8px 8px 16px 8px rgba(0, 0, 0, 0.2);
  }
  
  @media (max-width: 768px) {
    .experience {
      flex-direction: column;
    }
    .experience-description,
    .experience-image {
      width: 100%;
    }
  }
  

