@keyframes fade-in-projects-heading{
    from{scale: 0.8; opacity: 0;}
    to{scale: 1; opacity:1;}
  }

@-webkit-keyframes fade-in-projects-heading{
  from{scale: 0.8; opacity: 0;}
   to{scale: 1; opacity:1;}
}

.projects__projects-heading{
    font-family: var(--font-family);
    display: flex;
    font-size: 40px;
    font-weight: 800;
    margin-top: 80px;
    margin-bottom: 30px;
    padding-top: 30px;

    animation: fade-in-projects-heading linear forwards;
    animation-timeline: view();
    animation-range-start: entry;
    animation-range-end: exit -500px;
}

