@keyframes fade-in-projects{
  from{scale: 0.8;  opacity: 0;}
  to{scale: 1; opacity:1;}
}

@-webkit-keyframes fade-in-projects{
  from{scale: 0.8;  opacity: 0;}
  to{scale: 1; opacity:1;}
}

.project {
    display: flex;
    margin: 0 auto;
    padding: 40px;
    color: #f3f4f6(0, 0%, 20%);
    font-family: var(--font-family);
    display: flex;
    margin: 2em;
    background-color: #f2f2f2 ;
    /* #EEF2F8; */
    border-radius: 20px;
    overflow: hidden;
    opacity: 90%;
    transition: background-color 0.3s, box-shadow 0.3s, opacity 0.3s;

    animation: fade-in-projects linear forwards;
    animation-timeline: view();
    animation-range: entry;
    
  }
  
  .project h1 {
    font-size: 2em;
    margin-bottom: 0.5em;
  }
  

  .project:hover{
        background-color:#e9eaeb;
        box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.2);
        opacity: 100%;
  }
  
  .project-description {
    padding: 20px;
    width: 50%;
  }
  
  .project-description h2 {
    font-size: 2em;
    margin-bottom: 0.3em;
  }
  
  .project-description .technology {
    color: #666;
  }
  
  .project-description p {
    line-height: 1.6;
    font-size: 1.2em;
    margin-bottom: 1em;
  }

  .git__button{
    margin-top: 60px;
  }
  
  .git__button a{
    background-color: #EEF2F8; /* Button background */
    color: black; /* Text color */
    border: 2px solid black; 
    border-radius: 10px;
    padding: 10px 20px; /* Top/bottom and left/right padding */
    font-size: 16px; /* Text size */
    cursor: pointer; /* Cursor icon when hovering over the button */
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s; /* Smooth transition for background and text color */
    width: fit-content;
    margin-top: 50px;
    
  }

    .git__button a:hover{
    background-color: #00B2FF;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
  }
  
  .project-image {
    width: 50%;
  }
  
  .project-image img {
    width: 100%;
    height: auto;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s, opacity 0.3s;
  }
  .project-image img:hover{
    opacity: 100%;
    box-shadow: 8px 8px 16px 8px rgba(0, 0, 0, 0.2); ;
  }


  
  @media (max-width: 768px) {
    .project {
      flex-direction: column;
    }
    .project-description,
    .project-image {
      width: 100%;
    }
  }

