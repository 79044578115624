.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Courier New', Courier, monospace;
    font-size: 2em;
    color: black;
    z-index: 1000;
    transition: opacity 1s ease;
  }
  
  

  
  .fade-out {
    -webkit-animation: fade-out 1s ease-out both;
            animation: fade-out 1s ease-out both;
  }
  @-webkit-keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  